import { AppCommonProps } from "types/app"
import React, { useState, useEffect } from "react"
import { withI18n } from "~/hocs"
import { Dropdown } from "react-bootstrap"
import axios from "axios"

const DropdownApp = ({}: AppCommonProps) => {
  const [listItem, setListItem] = useState("")
  useEffect(async () => {
    const listItemSite = await axios.get(
      "https://accounts.ac-illust.com/get-list-apps",
      { params: { language: "jp" } },
    )
    setListItem(listItemSite.data)
  }, [])
  return (
    <div className="dropdown-section ml-2 mr-1">
      {/* Icon */}
      <span className="ac-apps-button ac-mr-1 ac-ml-1 dropdown-icon">
        <svg
          width="28"
          height="28"
          focusable="false"
          viewBox="0 0 24 24"
          fill="gray"
        >
          <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
        </svg>
      </span>

      {/* Dropdown Menu */}
      <div
        className="dropdown-menu-list-app"
        dangerouslySetInnerHTML={{ __html: listItem }}
      ></div>
    </div>
  )
}
export default withI18n(DropdownApp)
